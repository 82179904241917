import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, Row, Col, Figure } from "react-bootstrap"
import { Link, useHistory, useNavigate } from "react-router-dom"
import { auth } from '../Firebase/firebase.config';
import logo from '../recursos/logoHeader.png';
import { signInWithEmailAndPassword } from "firebase/auth";
import useFirebaseServices from "../Firebase/useFirebaseServices";
export default function Login() {
  
  
  
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  let config = JSON.parse(localStorage.getItem('config'));

  const { getSnapshot, updateSnapshot, getConfig } = useFirebaseServices();
  async function handleSubmit(e) {
    e.preventDefault();

    
    try {
      await signInWithEmailAndPassword(auth, email, password).then(async (data) => {

        console.log(data.user.uid)
        console.log(config)

        
        validateUser(data.user.uid)

         
        


      })
      .catch((error) => {
        const errorCode = error.code;
        setError("Verifique sus credenciales");
        console.log("Error de login: ", errorCode);
      });
    } catch { setError("Verifique sus credenciales");
    }
  }

  const validateUser = async (uid) => {


    let url = "configAdminWeb/users/";
    console.log(url)

    let dataUserGet = await getSnapshot(url);
    console.log(dataUserGet)

    let usuario = dataUserGet.find(item => item.id === uid)

    if (usuario === undefined) {
      setError("Su usuario no tiene acceso a este modulo.");
    }else{

      localStorage.setItem('datosUsuario', JSON.stringify(usuario));
      navigate("/homeAdmin")
    }


}


  function handleChangeE(event) {
    setEmail(event.target.value)
}

function handleChangeP(event) {
  setPassword(event.target.value)
}
  return (
    <>
      <Container fluid className="contenedor-maximo" style={{minHeight: "80vh !important"}}>


        <Container fluid style={{paddingBottom:15, minHeight: '950vh'}}  >

          <Row md={{ order: 1 }}  className="align-items-center mb-1  ">

            <Col sm={8} lg={8} md={8} xs={12} className='d-none d-sm-flex' >
            <h1>Módulo de Administración</h1>

            </Col>
            <Col sm={4} lg={4} md={4} xs={12}>
              <Figure fluid>
                <Figure.Image
                  height={70}
                  src={logo}
                />
              </Figure>
            </Col>


          </Row>
          <Card style={{margin:'auto', maxWidth: '50vh'}}>
          <Card.Title className="m-2">
            Inicio de sesión
            </Card.Title>
            <Card.Body>
              
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" onChange={handleChangeE} required  />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control type="password" onChange={handleChangeP} required />
                </Form.Group>
                <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" type="submit">
                 Iniciar sesion
                </Button>
              </Form>

            </Card.Body>
          </Card>

        </Container>
      </Container>

    </>
  )
}