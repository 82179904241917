import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase.config";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Figure, Form, Row, Table } from "react-bootstrap";
import useFirebaseServices from '../Firebase/useFirebaseServices';

import logo from '../recursos/logoHeader.png';

import Sidebar from "./SideBar";
import '../Pages/Dashboard.css'
import moment from "moment";
const Formulario = () => {
    const navigate = useNavigate();
    const user = auth.currentUser;
    const [formularios, setFormularios] = useState([])
    const [preguntas, setPreguntas] = useState([])
    const [preguntasSelect, setPreguntasSelect] = useState([])
    const [editarQuestionario, setEditarQuestionario] = useState(false)
    const [createQuestionario, setCreateQuestionario] = useState(false)
    const [formularioEditar, setFormularioEditar] = useState({})
    const [editarDescripcion, setEditarDescripcion] = useState("");
    const [editarIni, setEditarIni] = useState("");
    const [editarFin, setEditarFin] = useState("");
    const [editarRep, setEditarRep] = useState("");
    const [editarDias, setEditarDias] = useState("");
    const [editarEstado, setEditarEstado] = useState(false);

    
    const [findByEstado, setFindByEstado] = useState("")

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const { getSnapshot, updateSnapshot, getConfig, getCollection, setSnapshot, getCollectionOrden } = useFirebaseServices();

    useEffect(() => {

        console.log(user)

        if (user !== undefined && user !== null) {

            getFormulario()

        } else {
            console.log("no hay usuario")
            navigate("/login");
        }

    }, []);

    const verPreguntas = ()=>{
        navigate("/preguntas");
    }

    const getFormulario = async () => {


        let url = "forms/questionnaire/";
        let dataGeCo = await getCollectionOrden(url,"active");
        console.log(dataGeCo)

        var array = [];

        dataGeCo && dataGeCo.forEach((child) => {

            let data = {};
            data.id = child.key;
            data.idQ = child.val().id;
            data.descripcion = child.val().descripcion !== undefined ? child.val().descripcion : "";
            data.active = child.val().active !== undefined ? child.val().active : false;
            data.date_i = child.val().date_i !== undefined ? child.val().date_i : "";
            data.date_f = child.val().date_f !== undefined ? child.val().date_f : "";
            data.repetitions = child.val().repetitions !== undefined ? child.val().repetitions : "";
            data.validation_interval = child.val().validation_interval !== undefined ? child.val().validation_interval : "";
            data.questions = child.child("questions");
            array.push(data);
        })
        setFormularios(array)


        let url_pr = "forms/questions";
        let dataGePR = await getCollection(url_pr);
        console.log(dataGePR)
        var arrayP = [];

        dataGePR && dataGePR.forEach((child) => {
            let data = {};
            data.id = child.val().id;
            data.enunciado = child.val().enunciado !== undefined ? child.val().enunciado : "";
            data.type = child.val().type !== undefined ? child.val().type : "";

            arrayP.push(data)
        })

        console.log(arrayP)
        
        setPreguntas(arrayP)

    }

    const crearFormulario = () => {

        setCreateQuestionario(true)

    }
    const editarFormulario = (formulario) => {


        console.log(formulario)
        console.log(preguntas)
        setFormularioEditar(formulario)
        setEditarQuestionario(true)
        setEditarDescripcion(formulario.descripcion)
        setEditarIni(formulario.date_i+"T00:00:00.000")
        setEditarFin(formulario.date_f+"T00:00:00.000")
        setEditarRep(formulario.repetitions)
        setEditarDias(formulario.validation_interval)
        setPreguntasSelect(formulario.questions.val())
        setEditarEstado(`${formulario.active}`)
    }


    /**
     * Edicion de formulario
     */

    function handleCheckboxChange(event) {
        console.log(event.target.id)

        let newArray = [...preguntasSelect, event.target.id];
        if (preguntasSelect.includes(event.target.id)) {
            newArray = newArray.filter(id => id !== event.target.id);
        }
        setPreguntasSelect(newArray)
    };

    function handleRadioChange(event) {
        console.log(event.target.value)


        setEditarEstado(event.target.value)
    };

    const dateFromDateString = (dateString) => {
        return moment(new Date(dateString)).format('YYYY-MM-DDT00:00:00.000');
    };

    function handleChangeD(event) {
        setEditarDescripcion(event.target.value)
    }
    function handleChangeFi(event) {

        setEditarFin(dateFromDateString(event.target.value))
    }

    function handleChangeIni(event) {
        setEditarIni(dateFromDateString(event.target.value))
    }
    function handleChangeRep(event) {
        setEditarRep(event.target.value)
    }
    function handleChangeDia(event) {
        setEditarDias(event.target.value)
    }

    const logoutUser = async (e) => {
        e.preventDefault();

        await signOut(auth);
        navigate("/login");
    }

    async function handleSubmit(e) {
        e.preventDefault();

        console.log(editarIni)


        let dataToSave = {
            descripcion: editarDescripcion,
            active: editarEstado==="true"?true:false,
            date_i: editarIni.replace("T00:00:00.000",""),
            date_f: editarFin.replace("T00:00:00.000",""),
            repetitions: Number(editarRep),
            validation_interval: Number(editarDias),
            questions: preguntasSelect

        };

        let url_update = `forms/questionnaire/${formularioEditar.id}`;

        await updateSnapshot(url_update, dataToSave);

        getFormulario();
        setEditarQuestionario(false)


    }

    async function handleSubmitCreate(e) {
        e.preventDefault();


        let identificador = `Q${formularios.length + 1}`

        let dataToSave = {
            descripcion: editarDescripcion,
            active: true,
            date_i: editarIni.replace("T00:00:00.000",""),
            date_f: editarFin.replace("T00:00:00.000",""),
            repetitions: Number(editarRep),
            validation_interval: Number(editarDias),
            id: identificador,
            questions: preguntasSelect

        };

        let url_update = `forms/questionnaire/`;

        await setSnapshot(url_update, dataToSave);

        getFormulario();
        setCreateQuestionario(false)


    }

    const cancelarEditar = () => {

        setEditarQuestionario(false);
        setCreateQuestionario(false)
    }



    const dateForPicker = (dateString) => {
        console.log("dateString",dateString)
        console.log("dateStringtransform",moment(new Date(dateString)).format('YYYY-MM-DDT00:00:00.001'))
        return moment(new Date(dateString)).format('YYYY-MM-DD')
    };

    return (
        user !== undefined && user !== null && (
            <Container fluid className="contenedor-maximo">


                <Container fluid>
                    <Row className="align-items-center mb-1 ">
                        <Col sm={8} lg={8} md={8} xs={12} className='d-none d-sm-flex' >
                            <h1>Administracion de formularios</h1>

                        </Col>
                        <Col sm={4} lg={4} md={4} xs={12}>
                            <Figure fluid>
                                <Figure.Image
                                    height={70}
                                    src={logo}
                                />
                            </Figure>
                        </Col>


                    </Row>
                    <Row>
                        <Col xs={2} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        {!editarQuestionario && !createQuestionario ?
                            <Col xs={10} id="page-content-wrapper">
                                <Card className="d-flex flex-row mb-3" style={{ border: 0 }}>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" onClick={() => verPreguntas()}>
                                                        Ver preguntas
                                                    </Button>
                                                    <Button style={{ marginTop: '10px' }} size="xs" variant="boton-download" onClick={() => crearFormulario()} className="w-100" type="submit">
                                    Nuevo cuestionario</Button>
                                                </Card>
                                
                                <Table striped="columns">
                                    <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>Descripción</th>
                                            <th>Fecha inicial</th>
                                            <th>Fecha Final</th>
                                            <th>Estado</th>
                                            <th>Repeticiones</th>
                                            <th>Intervalo de Repetición (días)</th>
                                            <th>Editar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formularios.map(form => {
                                            return (
                                                <tr>
                                                    <td>{form.idQ}</td>
                                                    <td>{form.descripcion}</td>
                                                    <td>{form.date_i}</td>
                                                    <td>{form.date_f}</td>
                                                    <td>{form.active === true ? "Activo" : "Inactivo"}</td>
                                                    <td>{form.repetitions}</td>
                                                    <td>{form.validation_interval}</td>
                                                    <td onClick={() => editarFormulario(form)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                                    </svg></td>
                                                </tr>
                                            )
                                        })
                                        }


                                    </tbody>
                                </Table>
                            </Col>
                            : createQuestionario ?

                                <Col>

                                    <Card style={{ border: 0 }}>
                                        <Card.Title>
                                            Crear formulario
                                        </Card.Title>
                                        <Card.Body>
                                            {error && <Alert variant="danger">{error}</Alert>}
                                            <Form onSubmit={handleSubmitCreate}>
                                                <Form.Group id="descripcion">
                                                    <Form.Label className="font-weight-bold">Descripción</Form.Label>
                                                    <Form.Control type="text" onChange={handleChangeD} required />
                                                </Form.Group>
                                                <Form.Group id="fecha_i">
                                                    <Form.Label className="font-weight-bold">Fecha inicial</Form.Label>
                                                    <Form.Control type="date" onChange={handleChangeIni} required />
                                                </Form.Group>
                                                <Form.Group id="fecha_f">
                                                    <Form.Label className="font-weight-bold" l>Fecha final</Form.Label>
                                                    <Form.Control type="date" onChange={handleChangeFi} required />
                                                </Form.Group>
                                                <Form.Group id="repetitions">
                                                    <Form.Label className="font-weight-bold">Número de repeticiones</Form.Label>
                                                    <Form.Control type="number" onChange={handleChangeRep} required />
                                                </Form.Group>
                                                <Form.Group id="validation_interval">
                                                    <Form.Label className="font-weight-bold">Intervalo de repetición (días)</Form.Label>
                                                    <Form.Control type="number" onChange={handleChangeDia} required />
                                                </Form.Group>

                                                <Form.Group id="questions">
                                                    <Form.Label className="font-weight-bold">Seleccione las preguntas</Form.Label>
                                                    {preguntas && preguntas.length > 0 && preguntas.map(preg => {
                                                        return (
                                                            <Form.Check
                                                                type={'checkbox'}
                                                                name={"permission"}
                                                                id={preg.id}
                                                                label={preg.enunciado}
                                                                value={preg.id}
                                                                onChange={handleCheckboxChange}

                                                            />
                                                        )

                                                    })}
                                                </Form.Group>

                                                <Card className="d-flex flex-row mb-3" style={{ border: 0 }}>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" onClick={() => setCreateQuestionario(false)}>
                                                        Cancelar
                                                    </Button>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-guardar" disabled={loading} className="w-100" type="submit">
                                                        Guardar
                                                    </Button>
                                                </Card>
                                            </Form>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                :
                                <Col>
                                    <Card style={{ border: 0 }}>
                                        <Card.Title>
                                            Editar formulario {formularioEditar.idQ}
                                        </Card.Title>
                                        <Card.Body>
                                            {error && <Alert variant="danger">{error}</Alert>}
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Group id="descripcion">
                                                    <Form.Label className="font-weight-bold">Descripción</Form.Label>
                                                    <Form.Control type="text" onChange={handleChangeD} required value={editarDescripcion} />
                                                </Form.Group>
                                                <Form.Group id="fecha_i">
                                                    <Form.Label className="font-weight-bold">Fecha inicial</Form.Label>
                                                    <Form.Control type="date" onChange={handleChangeIni} required defaultValue={editarIni ? dateForPicker(editarIni) : ''}  />
                                                </Form.Group>
                                                <Form.Group id="fecha_f">
                                                    <Form.Label className="font-weight-bold" l>Fecha final</Form.Label>
                                                    <Form.Control type="date" onChange={handleChangeFi} required defaultValue={editarFin ? dateForPicker(editarFin) : ''} />
                                                </Form.Group>
                                                <Form.Group id="repetitions">
                                                    <Form.Label className="font-weight-bold">Número de repeticiones</Form.Label>
                                                    <Form.Control type="number" onChange={handleChangeRep} required value={editarRep} />
                                                </Form.Group>
                                                <Form.Group id="validation_interval">
                                                    <Form.Label className="font-weight-bold">Intervalo de repetición (días)</Form.Label>
                                                    <Form.Control type="number" onChange={handleChangeDia} required value={editarDias} />
                                                </Form.Group>
                                                
                                                <Form.Group controlId="editarEstado">
                                                    <Form.Label className="font-weight-bold">Estado</Form.Label>

                                                    <Form.Check
                                                        checked={editarEstado === "true"?true:false}
                                                        type={'radio'}
                                                        label={"Activo"}
                                                        value={true}
                                                        onChange={handleRadioChange}

                                                    />

                                                    <Form.Check
                                                        checked={editarEstado === "false"?true:false}
                                                        type={'radio'}
                                                        label={"Inactivo"}
                                                        value={false}
                                                        onChange={handleRadioChange}

                                                    />


                                                </Form.Group>

                                                <Form.Group id="questions">
                                                    <Form.Label className="font-weight-bold">Seleccione las preguntas</Form.Label>
                                                    {preguntas && preguntas.length > 0 && preguntas.map(preg => {
                                                        return (
                                                            <Form.Check
                                                                checked={preguntasSelect === null?"":preguntasSelect.find(item => item === preg.id)}
                                                                type={'checkbox'}
                                                                name={"permission"}
                                                                id={preg.id}
                                                                label={preg.enunciado}
                                                                value={preg.id}
                                                                onChange={handleCheckboxChange}

                                                            />
                                                        )

                                                    })}
                                                </Form.Group>
                                                <Card className="d-flex flex-row mb-3" style={{ border: 0 }}>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" onClick={() => cancelarEditar()}>
                                                        Cancelar
                                                    </Button>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-guardar" disabled={loading} className="w-100" type="submit">
                                                        Guardar
                                                    </Button>
                                                </Card>
                                            </Form>

                                        </Card.Body>
                                    </Card>
                                </Col>
                        }

                    </Row>

                </Container>

            </Container>
        )
    )
}

export default Formulario