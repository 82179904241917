import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase.config";
import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Figure, Form, Row, Table } from "react-bootstrap";
import useFirebaseServices from '../Firebase/useFirebaseServices';

import logo from '../recursos/logoHeader.png';

import Sidebar from "./SideBar";
import '../Pages/Dashboard.css'
import moment from "moment";
const Preguntas = () => {
    const navigate = useNavigate();
    const user = auth.currentUser;
    const [preguntas, setPreguntas] = useState([])

    const [editarPregunta, setEditarPregunta] = useState(false)
    const [createPregunta, setCreatePregunta] = useState(false)

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const { getSnapshot, updateSnapshot, getConfig, getCollection, setSnapshot } = useFirebaseServices();


    const [preguntaE, setPreguntaE] = useState({})
    const [editarEnunciado, setEditarEnunciado] = useState("");
    const [editarTipo, setEditarTipo] = useState("");
    const [editarRespuestaT, setEditarRespuestaT] = useState("");
    const [respuestasSelect, setRespuestasSelect] = useState([])

    function handleChangeEn(event) {
        setEditarEnunciado(event.target.value)
    }
    function handleChangeTi(event) {
        setEditarTipo(event.target.value)
        setEditarRespuestaT("");
        setRespuestasSelect([])
    }
    function handleChangeRespuestaT(event) {
        setEditarRespuestaT(event.target.value)
    }

    function handleChangeRespuesta() {



        let newArray = [...respuestasSelect, editarRespuestaT];
        if (respuestasSelect.includes(editarRespuestaT)) {
            newArray = newArray.filter(value => value !== editarRespuestaT);
        }
        setRespuestasSelect(newArray)
        setEditarRespuestaT("")
    }

    function removeRespuesta(resp) {


        let newArray = respuestasSelect.filter(value => value !== resp);

        setRespuestasSelect(newArray)
        setEditarRespuestaT("")
    }

    useEffect(() => {

        console.log(user)

        if (user !== undefined && user !== null) {

            getPreguntas()

        } else {
            console.log("no hay usuario")
            navigate("/login");
        }

    }, []);

    const verPreguntas = () => {
        navigate("/forms");
    }

    const getPreguntas = async () => {


        let url = "forms/questions";
        let dataGeCo = await getCollection(url);
        console.log(dataGeCo)

        var array = [];

        dataGeCo && dataGeCo.forEach((child) => {

            let data = {};
            data.id = child.key;
            data.idQ = child.val().id;
            data.enunciado = child.val().enunciado !== undefined ? child.val().enunciado : "";
            data.type = child.val().type !== undefined ? child.val().type : "";
            data.respuestas = child.child("respuestas");
            array.push(data);
        })
        setPreguntas(array)




    }


    const crearPRegunta = () => {

        setCreatePregunta(true)

    }


    async function handleSubmit(e) {
        e.preventDefault();





    }

    async function handleSubmitCreate(e) {
        e.preventDefault();

        let identificador = `Q${preguntas.length + 1}`

        let array = []
        if (editarTipo === "multiple_answer") {

            respuestasSelect.forEach((element, index) => {

                let a = {
                    id: index + 1,
                    item: element

                }
                array.push(a)

            });

        } else {
            array = respuestasSelect
        }


        let dataToSave = {
            enunciado: editarEnunciado,
            type: editarTipo,
            id: identificador,
            respuestas: array

        };

        let url_update = `forms/questions/`;

        await setSnapshot(url_update, dataToSave);

        getPreguntas();
        setCreatePregunta(false)
        setEditarEnunciado("")
        setEditarTipo("")
        setEditarRespuestaT("")
        setRespuestasSelect([])


    }

    async function handleSubmitEditar(e) {
        e.preventDefault();

        

        let array = []
        if (editarTipo === "multiple_answer") {

            respuestasSelect.forEach((element, index) => {

                let a = {
                    id: index + 1,
                    item: element

                }
                array.push(a)

            });

        } else {
            array = respuestasSelect
        }


        let dataToSave = {
            enunciado: editarEnunciado,
            type: editarTipo,
            respuestas: array

        };

        let url_update = `forms/questions/${preguntaE.id}`;

        await updateSnapshot(url_update, dataToSave);

        getPreguntas();
        setEditarPregunta(false)
        setEditarEnunciado("")
        setEditarTipo("")
        setEditarRespuestaT("")
        setRespuestasSelect([])


    }
    const editarPreguntaF = (item) => {

        console.log(item.respuestas.val())
        setEditarPregunta(true)
        setPreguntaE(item)
        setEditarEnunciado(item.enunciado)
        setEditarTipo(item.type)
        setEditarRespuestaT("")
        let arrayR = [];

        if (item.type === "multiple_answer") {

            item.respuestas.val().forEach(element => {

                arrayR.push(element.item)

            });
        } else {
            item.respuestas.val().forEach(element => {

                arrayR.push(element)

            });
        }
        setRespuestasSelect(arrayR)
    }


    const cancelarEditar = () => {

        setEditarPregunta(false)
        setEditarEnunciado("")
        setEditarTipo("")
        setEditarRespuestaT("")
        setRespuestasSelect([])
    }

    const cancelarCrear = () => {
        setCreatePregunta(false)
        setEditarEnunciado("")
        setEditarTipo("")
        setEditarRespuestaT("")
        setRespuestasSelect([])

    }



    const dateForPicker = (dateString) => {
        console.log("dateString", dateString)
        console.log("dateStringtransform", moment(new Date(dateString)).format('YYYY-MM-DDT00:00:00.001'))
        return moment(new Date(dateString)).format('YYYY-MM-DD')
    };

    return (
        user !== undefined && user !== null && (
            <Container fluid className="contenedor-maximo">


                <Container fluid>
                    <Row className="align-items-center mb-1 ">
                        <Col sm={8} lg={8} md={8} xs={12} className='d-none d-sm-flex' >
                            <h1>Administración de preguntas</h1>

                        </Col>
                        <Col sm={4} lg={4} md={4} xs={12}>
                            <Figure fluid>
                                <Figure.Image
                                    height={70}
                                    src={logo}
                                />
                            </Figure>
                        </Col>


                    </Row>
                    <Row>
                        <Col xs={2} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        {!editarPregunta && !createPregunta ?
                            <Col xs={10} id="page-content-wrapper">
                                <Card className="d-flex flex-row mb-3" style={{ border: 0 }}>
                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" onClick={() => verPreguntas()}>
                                        Volver
                                    </Button>
                                    <Button style={{ marginTop: '10px' }} size="xs" variant="boton-download" onClick={() => crearPRegunta()} className="w-100" type="submit">
                                        Nueva pregunta</Button>
                                </Card>

                                <Table striped="columns">
                                    <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>Enunciado</th>
                                            <th>Tipo</th>
                                            <th>Editar</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {preguntas.map(form => {
                                            return (
                                                <tr>
                                                    <td>{form.idQ}</td>
                                                    <td>{form.enunciado}</td>
                                                    <td>{form.type === "simple" ? "Abierta" : form.type === "multiple" ? "Selección Unica" : "Selección mútiple"}</td>
                                                    <td onClick={() => editarPreguntaF(form)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                                    </svg></td>
                                                </tr>
                                            )
                                        })
                                        }


                                    </tbody>
                                </Table>
                            </Col>
                            : createPregunta ?
                                <Col>
                                    <Card style={{ border: 0 }}>
                                        <Card.Title>
                                            Crear pregunta
                                        </Card.Title>
                                        <Card.Body>
                                            {error && <Alert variant="danger">{error}</Alert>}
                                            <Form onSubmit={handleSubmitCreate}>
                                                <Form.Group id="enunciado">
                                                    <Form.Label className="font-weight-bold">Enunciado</Form.Label>
                                                    <Form.Control type="text" onChange={handleChangeEn} required />
                                                </Form.Group>
                                                <br />
                                                <Form.Group id="tipo">
                                                    <Form.Label className="font-weight-bold">Tipo</Form.Label>
                                                    <br />
                                                    <Form.Select size="lg" onChange={handleChangeTi} required>
                                                        <option value="">Seleccione una opción</option>
                                                        <option value="simple">Abierta</option>
                                                        <option value="multiple">Selección Única</option>
                                                        <option value="multiple_answer">Selección múltiple</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <br />
                                                {editarTipo !== "" && (editarTipo === "multiple" || editarTipo === "multiple_answer") && (

                                                    <Card style={{ border: 0 }}>

                                                        <Card.Title>Registrar respuestas</Card.Title>
                                                        {respuestasSelect.length > 0 && (
                                                            <Table striped bordered hover size="sm" style={{ width: "fit-content" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Respuesta</th>
                                                                        <th>Eliminar</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {respuestasSelect.length > 0 && respuestasSelect.map((resp, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{resp}</td>
                                                                                <td onClick={() => removeRespuesta(resp)}>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                                    </svg>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        )}

                                                        < Form.Group id="respuesta">
                                                            <Form.Label className="font-weight-bold">Respuesta</Form.Label>
                                                            <Card className="d-flex flex-row mb-3" style={{ width: 300, ml: 5, border: 0, }}>
                                                                <Form.Control type="text" onChange={handleChangeRespuestaT} value={editarRespuestaT} style={{ width: 300, marginLeft: "10px" }} />
                                                                <td onClick={handleChangeRespuesta}>

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                                                    </svg>
                                                                </td>
                                                            </Card>
                                                        </Form.Group>
                                                    </Card>

                                                )

                                                }



                                                <Card className="d-flex flex-row mb-3" style={{ border: 0 }}>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" onClick={() => cancelarCrear()}>
                                                        Cancelar
                                                    </Button>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-guardar" disabled={loading} className="w-100" type="submit">
                                                        Guardar
                                                    </Button>
                                                </Card>
                                            </Form>
                                        </Card.Body>

                                    </Card>
                                </Col>
                                :
                                <Col>
                                    <Card style={{ border: 0 }}>
                                        <Card.Title>
                                           Editar pregunta {editarPreguntaF.id}
                                        </Card.Title>
                                        <Card.Body>
                                            {error && <Alert variant="danger">{error}</Alert>}
                                            <Form onSubmit={handleSubmitEditar}>
                                                <Form.Group id="enunciado">
                                                    <Form.Label className="font-weight-bold">Enunciado</Form.Label>
                                                    <Form.Control type="text" onChange={handleChangeEn} required value={preguntaE.enunciado} />
                                                </Form.Group>
                                                <br />
                                                <Form.Group id="tipo">
                                                    <Form.Label className="font-weight-bold">Tipo</Form.Label>
                                                    <br />
                                                    <Form.Select size="lg" onChange={handleChangeTi} required value={preguntaE.type}>
                                                    <option value="">Seleccione una opción</option>
                                                        <option value="simple">Abierta</option>
                                                        <option value="multiple">Selección Única</option>
                                                        <option value="multiple_answer">Selección múltiple</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <br />
                                                {editarTipo !== "" && (editarTipo === "multiple" || editarTipo === "multiple_answer") && (

                                                    <Card style={{ border: 0 }}>

                                                        <Card.Title>Registrar respuestas</Card.Title>
                                                        {respuestasSelect.length > 0 && (
                                                            <Table striped bordered hover size="sm" style={{ width: "fit-content" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Respuesta</th>
                                                                        <th>Eliminar</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {respuestasSelect.length > 0 && respuestasSelect.map((resp, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{resp}</td>
                                                                                <td onClick={() => removeRespuesta(resp)}>

                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                                                    </svg>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        )}

                                                        < Form.Group id="respuesta">
                                                            <Form.Label className="font-weight-bold">Respuesta</Form.Label>
                                                            <Card className="d-flex flex-row mb-3" style={{ width: 300, ml: 5, border: 0, }}>
                                                                <Form.Control type="text" onChange={handleChangeRespuestaT} value={editarRespuestaT} style={{ width: 300, marginLeft: "10px" }} />
                                                                <td onClick={handleChangeRespuesta}>

                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                                                    </svg>
                                                                </td>
                                                            </Card>
                                                        </Form.Group>
                                                    </Card>

                                                )

                                                }



                                                <Card className="d-flex flex-row mb-3" style={{ border: 0 }}>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" onClick={() => cancelarEditar()}>
                                                        Cancelar
                                                    </Button>
                                                    <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-guardar" disabled={loading} className="w-100" type="submit">
                                                        Guardar
                                                    </Button>
                                                </Card>
                                            </Form>
                                        </Card.Body>

                                    </Card>
                                </Col>
                        }

                    </Row>

                </Container>

            </Container >
        )
    )
}

export default Preguntas