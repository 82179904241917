import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig=
    {
        apiKey: "AIzaSyB--W4wRJJcoUEOaU-UdMRzkVkPRZdSMfo",
        authDomain: "oimcol.firebaseapp.com",
        databaseURL: "https://oimcol-default-rtdb.firebaseio.com",
        projectId: "oimcol",
        storageBucket: "oimcol.appspot.com",
        messagingSenderId: "70900825873",
        appId: "1:70900825873:web:61a38976380b3819e396b0",
        measurementId: "G-REHXBLGDBH"
    }



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
