import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase.config";
import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { Col, Container, Figure, Row } from "react-bootstrap";
import useFirebaseServices from '../Firebase/useFirebaseServices';

import logo from '../recursos/logoHeader.png';
import Sidebar from "./SideBar";

import '../Pages/Dashboard.css'
const HomeAdminPage = () => {
    const navigate = useNavigate();
    const user = auth.currentUser;

    const { getSnapshot, updateSnapshot, getConfig } = useFirebaseServices();

    useEffect(() => {

        console.log(user)

        if (user !== undefined && user !== null) {



        } else {
            console.log("no hay usuario")
            navigate("/login");
        }

    }, []);



    const logoutUser = async (e) => {
        e.preventDefault();

        await signOut(auth);
        navigate("/login");
    }

    return (
        user !== undefined && user !== null && (
            <Container fluid className="contenedor-maximo">


                <Container fluid>
                    <Row  className="align-items-center mb-1 ">
                    <Col sm={8} lg={8} md={8} xs={12} className='d-none d-sm-flex' >
                            <h1>Módulo de Administración</h1>

                        </Col>
                        <Col sm={4} lg={4} md={4} xs={12}>
                            <Figure fluid>
                                <Figure.Image
                                    height={70}
                                    src={logo}
                                />
                            </Figure>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={2} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        
                    </Row>

                </Container>

            </Container>
        )
    )
}

export default HomeAdminPage