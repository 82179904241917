import './App.css';
import { AuthProvider } from './app-context';
import Homepage from './Pages/HomePage'
import { useEffect } from 'react';
import useFirebaseServices from './Firebase/useFirebaseServices';
import { Route, Routes } from 'react-router-dom';
import Formulario from './Pages/Formulario';
import Login from './Pages/Login';
import HomeAdminPage from './Pages/HomeAdminPage';
import Preguntas from './Pages/Preguntas';
import Usuarios from './Pages/Usuarios';
import Descargas from './Pages/Descargas';
function App() {


  const { getSnapshot, updateSnapshot, getConfig } = useFirebaseServices();
  useEffect(() => {

    getConfigu();


  }, []);

  const getConfigu = async () => {


    let dataGet = await getConfig();


    localStorage.setItem('config', JSON.stringify(dataGet));

  }

  return (
    <AuthProvider>
      <Routes>
        <Route path="/forms" element={<Formulario />} />
        <Route path="/homeAdmin" element={<HomeAdminPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/preguntas" element={<Preguntas />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/descargas" element={<Descargas />} />
        <Route path="/" element={<Homepage />} />
      </Routes>


    </AuthProvider>
  );
}

export default App;
