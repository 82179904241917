import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase.config";
import { deleteUser, sendPasswordResetEmail, signOut, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Figure, Form, Row, Table, ButtonGroup } from "react-bootstrap";
import useFirebaseServices from '../Firebase/useFirebaseServices';

import logo from '../recursos/logoHeader.png';

import Sidebar from "./SideBar";
import '../Pages/Dashboard.css'
import moment from "moment";


const Descargas = () => {
    const navigate = useNavigate();
    const user = auth.currentUser;


    const { getSnapshot, updateSnapshot, getConfig, getCollection, setSnapshot, getCollectionLimit, getCollectionLimitStart, getCollectionLimitBefore } = useFirebaseServices();

    const [usuariosListT, setUsuariosListT] = useState([])
    const [usuariosCoord, setUsuariosCoord] = useState([])



    useEffect(() => {



        if (user !== undefined && user !== null) {
            getUsuariosList()


        } else {
            console.log("no hay usuario")
            navigate("/login");
        }

    }, []);

    const getUsuariosList = async () => {


        let urlCoor = "coordenadas/";
        let dataCoord = await getCollection(urlCoor);

        var array3 = [];

        var tempCollection = [];
        dataCoord.forEach(collection => {
            tempCollection.push(collection);
        });

        console.log(tempCollection)


        for (let i = 0; i < tempCollection.length; i++) {

            let aaa = ""
            let data = {};
            data.uid = tempCollection[i].key;

            let array2 = [];
            tempCollection[i].forEach(element => {
                let data2 = {};
                data2.fecha = element.val().fecha;
                data2.latitud = element.val().latitud;
                data2.longitud = element.val().longitud;
                array2.push(data2)


            });
            data.coordenadas = array2


            array3.push(data);


        }
        console.log(array3)


        setUsuariosCoord(array3)



    }



    return (
        user !== undefined && user !== null && (
            <Container fluid className="contenedor-maximo">

                <Container fluid>
                    <Row className="align-items-center mb-1 ">
                        <Col sm={8} lg={8} md={8} xs={12} className='d-none d-sm-flex' >
                            <h1>Descarga de Reportes</h1>

                        </Col>
                        <Col sm={4} lg={4} md={4} xs={12}>
                            <Figure fluid>
                                <Figure.Image
                                    height={70}
                                    src={logo}
                                />
                            </Figure>
                        </Col>


                    </Row>
                    <Row>
                        <Col xs={2} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>

                        <Col xs={10} id="page-content-wrapper">

                            <Card style={{ maxWidth: '80vh' }}>
                                <Card.Title className="m-2">
                                    Reportes
                                </Card.Title>
                                <Card.Body className="m-2">

                                    <Row className="mb-3" style={{ justifyContent: "flex-start" }}>
                                        <a
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                JSON.stringify(usuariosListT)
                                            )}`}
                                            download="filename.json"
                                        >
                                            <Button size="xxl" variant="boton-download">
                                            Usuarios (json)
                                            </Button>
                                        </a>
                                    </Row>
                                    <Row className="mb-3" style={{ justifyContent: "flex-start" }}>
                                        <a
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                JSON.stringify(usuariosCoord)
                                            )}`}
                                            download="filename.json"
                                        >
                                            <Button size="xxl" variant="boton-download">
                                                Coordenadas (json)
                                            </Button>
                                        </a>
                                    </Row>

                                </Card.Body>
                            </Card>

                        </Col>


                    </Row>
                </Container>
            </Container>

        ))

}

export default Descargas