import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase.config";
import { deleteUser, sendPasswordResetEmail, signOut, getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Figure, Form, Row, Table, ButtonGroup } from "react-bootstrap";
import useFirebaseServices from '../Firebase/useFirebaseServices';

import logo from '../recursos/logoHeader.png';

import Sidebar from "./SideBar";
import '../Pages/Dashboard.css'
import moment from "moment";


const Usuarios = () => {
    const navigate = useNavigate();
    const user = auth.currentUser;


    const [error, setError] = useState("")
    const [mensaje, setMensaje] = useState("")
    const [loading, setLoading] = useState(false)
    const { getSnapshot, updateSnapshot, getConfig, getCollection, setSnapshot, getCollectionLimit, getCollectionLimitStart, getCollectionLimitBefore } = useFirebaseServices();
    const [limit, setLimit] = useState();
    const [page, setPage] = useState(1);
    const [usuariosList, setUsuariosList] = useState([])

    const [usuariosListT, setUsuariosListT] = useState([])
    const [usuariosCoord, setUsuariosCoord] = useState([])

    const [editarUsuario, setEditarUsuario] = useState(false)
    const [findByEmail, setFindByEmail] = useState("")


    const [usuarioAEditar, setUsuarioAEditar] = useState({});

    const [editarGenero, setEditarGenero] = useState("");
    const [editarFechaNac, setEditarFechaNac] = useState("");

    const [editarAceptaTyC, setEditarAceptaTyC] = useState(false);
    const [editarPersonaMayor, setEditarPersonaMayor] = useState(false);


    useEffect(() => {



        if (user !== undefined && user !== null) {
            getUsuariosList()


        } else {
            console.log("no hay usuario")
            navigate("/login");
        }

    }, []);

    const getUsuariosList = async () => {


        
        let url = "users/";
        let dataGeCo = await getCollectionLimit(url, "email", 5, findByEmail);


        var array = [];

        dataGeCo && dataGeCo.forEach((child) => {


            let data = {};
            data.id = child.key;
            data.aceptaTyC = child.val().aceptaTyC !== undefined ? child.val().aceptaTyC : false;
            data.birdDate = child.val().birdDate !== undefined ? child.val().birdDate : "";
            data.email = child.val().email !== undefined ? child.val().email : "";
            data.oldMen = child.val().oldMen === undefined && child.val().oldMen === "" ? false : child.val().oldMen;
            data.gender = child.val().gender !== undefined ? child.val().gender : "";
            data.timestamp = child.val().timestamp !== undefined ? child.val().timestamp : "";
            array.push(data);
        })
        console.log(array)
        setUsuariosList(array)

    }

    const editarUsuarios = (usuario) => {

        setUsuarioAEditar(usuario);
        setEditarFechaNac(usuario.birdDate + "T00:00:00.000")
        setEditarAceptaTyC(`${usuario.aceptaTyC}`)
        setEditarPersonaMayor(`${usuario.oldMen}`)
        setEditarGenero(usuario.gender)
        setFindByEmail("")
        setEditarUsuario(true)

    }

    const showNext = ({ item }) => {
        console.log(item)

        if (usuariosList.length === 0) {
            alert("Thats all we have for now !")
        } else {
            const fetchNextData = async () => {
                let url = "users/";
                let dataGeCo = await getCollectionLimitStart(url, "email", 5, item.timestamp, findByEmail);
                console.log(dataGeCo)
                var array = [];

                dataGeCo && dataGeCo.forEach((child) => {

                    let data = {};
                    data.id = child.key;
                    data.aceptaTyC = child.val().aceptaTyC !== undefined ? child.val().aceptaTyC : false;
                    data.birdDate = child.val().birdDate !== undefined ? child.val().birdDate : "";
                    data.email = child.val().email !== undefined ? child.val().email : "";
                    data.oldMen = child.val().oldMen === undefined && child.val().oldMen === "" ? false : child.val().oldMen;
                    data.gender = child.val().gender !== undefined ? child.val().gender : "";
                    data.timestamp = child.val().timestamp !== undefined ? child.val().timestamp : "";
                    array.push(data);
                })
                setUsuariosList(array)
                setPage(page + 1)
            };
            fetchNextData();
        }
    };

    const showPrevious = ({ item }) => {

        console.log(item)
        const fetchPreviousData = async () => {
            let url = "users/";
            let dataGeCo = await getCollectionLimitBefore(url, "email", 5, item.timestamp, findByEmail);
            var array = [];

            dataGeCo && dataGeCo.forEach((child) => {

                let data = {};
                data.id = child.key;
                data.aceptaTyC = child.val().aceptaTyC !== undefined ? child.val().aceptaTyC : false;
                data.birdDate = child.val().birdDate !== undefined ? child.val().birdDate : "";
                data.email = child.val().email !== undefined ? child.val().email : "";
                data.oldMen = child.val().oldMen === undefined && child.val().oldMen === "" ? false : child.val().oldMen;
                data.gender = child.val().gender !== undefined ? child.val().gender : "";
                data.timestamp = child.val().timestamp !== undefined ? child.val().timestamp : "";
                array.push(data);
            })
            setUsuariosList(array)
            setPage(page - 1)

        };
        fetchPreviousData();
    };

    function handleChangeE(event) {
        setFindByEmail(event.target.value)
    }

    function handleChangeGe(event) {
        setEditarGenero(event.target.value)
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setFindByEmail(e.target.value)
        getUsuariosList()


    }

    async function handleSubmitE(e) {
        e.preventDefault();

        let dataToSave = {
            gender: editarGenero,
            oldMen: editarPersonaMayor === "true" ? true : false,
            aceptaTyC: editarAceptaTyC === "true" ? true : false,
            birdDate: editarFechaNac.replace("T00:00:00.000", ""),
            updateAt: new Date()
        };

        let url_update = `users/${usuarioAEditar.id}`;

        await updateSnapshot(url_update, dataToSave);


        setEditarUsuario(false);
        setEditarAceptaTyC(false)
        setEditarPersonaMayor(false)
        setEditarGenero("")
        setEditarFechaNac("")

        setFindByEmail("")
        getUsuariosList();


    }

    function handleChangeFecha(event) {
        setEditarFechaNac(dateFromDateString(event.target.value))
    }

    const dateForPicker = (dateString) => {
        console.log("dateString", dateString)
        console.log("dateStringtransform", moment(new Date(dateString)).format('YYYY-MM-DDT00:00:00.001'))
        return moment(new Date(dateString)).format('YYYY-MM-DD')
    };

    function handleRadioChangeAd(event) {
        console.log(event.target.value)


        setEditarPersonaMayor(event.target.value)
    };

    function handleRadioChangeTyC(event) {
        console.log(event.target.value)


        setEditarAceptaTyC(event.target.value)
    };

    const dateFromDateString = (dateString) => {
        return moment(new Date(dateString)).format('YYYY-MM-DDT00:00:00.000');
    };

    const cancelarEditar = () => {

        setEditarUsuario(false);
        setEditarAceptaTyC(false)
        setEditarPersonaMayor(false)
        setEditarGenero("")
        setEditarFechaNac("")

        setFindByEmail("")
    }

    const restablecerPass = async (email) => {



        try {
            await sendPasswordResetEmail(auth, email).then(async (data) => {

                console.log(data)

                setMensaje("Se envio correctamente el correo de recuperacion")


            })
                .catch((error) => {
                    const errorCode = error.code;
                    setError(errorCode);
                    console.log("Error de login: ", errorCode);
                });
        } catch {
            setError("Error al enviar el correo");
        }
    }

    return (
        user !== undefined && user !== null && (
            <Container fluid className="contenedor-maximo">

                <Container fluid>
                    <Row className="align-items-center mb-1 ">
                        <Col sm={8} lg={8} md={8} xs={12} className='d-none d-sm-flex' >
                            <h1>Administracion de usuarios</h1>

                        </Col>
                        <Col sm={4} lg={4} md={4} xs={12}>
                            <Figure fluid>
                                <Figure.Image
                                    height={70}
                                    src={logo}
                                />
                            </Figure>
                        </Col>


                    </Row>
                    <Row>
                        <Col xs={2} id="sidebar-wrapper">
                            <Sidebar />
                        </Col>
                        {!editarUsuario ?
                            <Col xs={10} id="page-content-wrapper">



                                <Alert show={mensaje !== ""} variant="success">
                                    {mensaje}
                                </Alert>
                                <Alert show={error !== ""} variant="danger">
                                    {error}
                                </Alert>
                                <Row className="mb-3">
                                    <div className="border border-3 border-primary"></div>
                                    <Card className="shadow" style={{ minWidth: "500px" }}>
                                        <Card.Body>
                                            <div>
                                                <h2 className="fw-bold mb-2 text-uppercase">Filtro</h2>
                                                <p className=" mb-2">Ingrese el correo a buscar</p>
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group className="mb-3" controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" onChange={handleChangeE} required />
                                                    </Form.Group>
                                                    <Button style={{ marginTop: '10px' }} size="sm" variant="boton-buscar" disabled={loading} type="submit">
                                                        Buscar
                                                    </Button>
                                                </Form>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                
                                <Row>

                                    <Table striped="columns">
                                        <thead>
                                            <tr>
                                                <th>id</th>
                                                <th>Email</th>
                                                <th>Fecha nacimiento</th>
                                                <th>Genero</th>
                                                <th>Acepta TYC</th>
                                                <th>Persona Mayor</th>
                                                <th>Editar</th>
                                                <th>Restablecer contraseña</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usuariosList.map(form => {
                                                return (
                                                    <tr>
                                                        <td align="cen">{form.id}</td>
                                                        <td>{form.email}</td>
                                                        <td>{form.birdDate}</td>
                                                        <td>{form.gender === "M" ? "Masculino" : "Femenino"}</td>
                                                        <td>{form.aceptaTyC === false ? "No" : "Si"}</td>
                                                        <td>{form.oldMen === false ? "No" : "Si"}</td>
                                                        <td align="center" onClick={() => editarUsuarios(form)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                                        </svg></td>
                                                        <td align="center" onClick={() => restablecerPass(form.email)}>

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-recycle" viewBox="0 0 16 16">
                                                                <path d="M9.302 1.256a1.5 1.5 0 0 0-2.604 0l-1.704 2.98a.5.5 0 0 0 .869.497l1.703-2.981a.5.5 0 0 1 .868 0l2.54 4.444-1.256-.337a.5.5 0 1 0-.26.966l2.415.647a.5.5 0 0 0 .613-.353l.647-2.415a.5.5 0 1 0-.966-.259l-.333 1.242zM2.973 7.773l-1.255.337a.5.5 0 1 1-.26-.966l2.416-.647a.5.5 0 0 1 .612.353l.647 2.415a.5.5 0 0 1-.966.259l-.333-1.242-2.545 4.454a.5.5 0 0 0 .434.748H5a.5.5 0 0 1 0 1H1.723A1.5 1.5 0 0 1 .421 12.24zm10.89 1.463a.5.5 0 1 0-.868.496l1.716 3.004a.5.5 0 0 1-.434.748h-5.57l.647-.646a.5.5 0 1 0-.708-.707l-1.5 1.5a.5.5 0 0 0 0 .707l1.5 1.5a.5.5 0 1 0 .708-.707l-.647-.647h5.57a1.5 1.5 0 0 0 1.302-2.244z" />
                                                            </svg>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </Table>

                                    <ButtonGroup className="mb-2">
                                        {
                                            //show previous button only when we have items
                                            page === 1 ? '' :
                                                <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" onClick={() => showPrevious({ item: usuariosList[0] })}>Previous</Button>
                                        }

                                        {
                                            //show next button only when we have items
                                            usuariosList.length < 5 ? '' :
                                                <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-download" onClick={() => showNext({ item: usuariosList[usuariosList.length - 1] })}>Next</Button>
                                        }
                                    </ButtonGroup>
                                </Row>


                            </Col>
                            :
                            <Card style={{ border: 0 }}>
                                <Card.Title>
                                    Editar usuario {usuarioAEditar.id}  - {usuarioAEditar.email}
                                </Card.Title>
                                <Card.Body>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    <Form onSubmit={handleSubmitE}>
                                        <Form.Group id="">
                                            <Form.Label className="font-weight-bold">Fecha Nacimiento</Form.Label>
                                            <Form.Control type="date" onChange={handleChangeFecha} required defaultValue={editarFechaNac ? dateForPicker(editarFechaNac) : ''} />
                                        </Form.Group>

                                        <Form.Group id="">
                                            <Form.Label className="font-weight-bold">Género</Form.Label>
                                            <br />
                                            <Form.Select size="lg" onChange={handleChangeGe} required value={editarGenero}>
                                                <option value="">Seleccione una opcion</option>
                                                <option value="M">Masculino</option>
                                                <option value="F">Femenino</option>
                                            </Form.Select>
                                        </Form.Group>


                                        <Form.Group controlId="editarAceptaTyC">
                                            <Form.Label className="font-weight-bold">Acepta terminos y condiciones</Form.Label>

                                            <Form.Check
                                                checked={editarAceptaTyC === "true" ? true : false}
                                                type={'radio'}
                                                label={"Si"}
                                                value={true}
                                                onChange={handleRadioChangeTyC}

                                            />

                                            <Form.Check
                                                checked={editarAceptaTyC === "false" ? true : false}
                                                type={'radio'}
                                                label={"No"}
                                                value={false}
                                                onChange={handleRadioChangeTyC}

                                            />


                                        </Form.Group>
                                        <Form.Group controlId="editarAdultoMayor">
                                            <Form.Label className="font-weight-bold">Adulto mayor</Form.Label>

                                            <Form.Check
                                                checked={editarPersonaMayor === "true" ? true : false}
                                                type={'radio'}
                                                label={"Si"}
                                                value={true}
                                                onChange={handleRadioChangeAd}

                                            />

                                            <Form.Check
                                                checked={editarPersonaMayor === "false" ? true : false}
                                                type={'radio'}
                                                label={"No"}
                                                value={false}
                                                onChange={handleRadioChangeAd}

                                            />


                                        </Form.Group>


                                        <Card className="d-flex flex-row mb-3" style={{ border: 0 }}>
                                            <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-buscar" disabled={loading} className="w-100" onClick={() => cancelarEditar()}>
                                                Cancelar
                                            </Button>
                                            <Button style={{ marginTop: '10px' }} size="xxl" variant="boton-guardar" disabled={loading} className="w-100" type="submit">
                                                Guardar
                                            </Button>
                                        </Card>
                                    </Form>

                                </Card.Body>
                            </Card>
                        }
                    </Row>
                </Container>
            </Container>

        ))

}

export default Usuarios