import {
  getDatabase,
  ref,
  child,
  get,
  set,
  push,
  update,
  remove,
  orderByChild,
  limitToLast,
  query,
  startAfter,
  endBefore,
  limitToFirst,
  equalTo
} from "firebase/database";

const dbRef = ref(getDatabase());
const db = getDatabase();

const useFirebaseServices = () => {
  const getSnapshot = async (data) => {
    return get(child(dbRef, data))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let userGet = snapshot.val();
          return userGet;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getConfig = async () => {
    return get(child(dbRef, "configWebApis"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let userGet = snapshot.val();
          return userGet;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  

  const updateSnapshot = async (request, data) => {
    return update(ref(db, request), data)
      .then((data) => {
        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setSnapshot = async (request, data) => {
    return push(ref(db, request), data).catch((error) => {
      console.error(error);
    });
  };

  const getCollection = async (data) => {
    return get(child(dbRef, data))
      .then((snapshot) => {
        if (snapshot.exists()) {
          return snapshot;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCollectionLimit = async (data,order,limit,equal) => {

    const q = query(ref(db, data), orderByChild(order), limitToFirst(limit), equalTo(equal));

    return get(q)
    
      .then((snapshot) => {
        console.log(snapshot)
        if (snapshot.exists()) {
          return snapshot;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCollectionLimitStart = async (data,order,limit,item,equal) => {

    console.log(item)
    

    const q = query(ref(db, data), orderByChild(order), limitToFirst(limit), startAfter(item));

    return get(q)
    
      .then((snapshot) => {
        console.log(snapshot)
        if (snapshot.exists()) {
          return snapshot;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCollectionLimitBefore = async (data,order,limit,item,equal) => {

    const q = query(ref(db, data), orderByChild(order), limitToFirst(limit), endBefore(item));

    return get(q)
    
      .then((snapshot) => {
        console.log(snapshot)
        if (snapshot.exists()) {
          return snapshot;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCollectionOrden = async (data,order) => {
   
    const q = query(ref(db, data), orderByChild(order));

    return get(q)
    
      .then((snapshot) => {
        console.log(snapshot)
        if (snapshot.exists()) {
          return snapshot;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCollectionData = async (data) => {
    return get(child(dbRef, data))
      .then((snapshot) => {
        if (snapshot.exists()) {
          return snapshot;
        } else {
          return null;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCollectionContrato = async (contrato) => {

    var myHeaders = new Headers();

        let arrayContrato = {
            "numeroContrato": contrato
        }

        var raw = JSON.stringify(arrayContrato);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            crossDomain: true,
            redirect: 'follow'
        };

    return fetch("https://sapfiori.ramo.com.co:44301/RESTAdapter/ramoetrade/ContratosMarco", requestOptions)
    .then(response => response.json())
    .then(result => {
        return result;
    })


    .catch(e => {
        console.log(e)
    });
  };

  const removeSnapshot = async (request) => {
    return remove(ref(db, request)).catch((error) => {
      console.error(error);
    });
  };

  return {
    getSnapshot,
    getCollection,
    getCollectionOrden,
    getCollectionData,
    updateSnapshot,
    setSnapshot,
    removeSnapshot,
    getCollectionContrato,
    getConfig,
    getCollectionLimit,
    getCollectionLimitStart,
    getCollectionLimitBefore
  };
};

export default useFirebaseServices;
