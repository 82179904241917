import React, { useEffect, useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { withRouter } from "react-router";
import '../Pages/Dashboard.css'
import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase/firebase.config";
import { signOut } from "firebase/auth";
import useFirebaseServices from '../Firebase/useFirebaseServices';
const Side = props => {


    const navigate = useNavigate();
    const user = auth.currentUser;

    const [menus, setMenus] = useState([])
    const { getSnapshot, updateSnapshot, getConfig } = useFirebaseServices();

    useEffect(() => {
        let dataUsuaro = JSON.parse(localStorage.getItem('datosUsuario'));

        if(dataUsuaro.rol!==undefined){

        setMenus(dataUsuaro.rol)
        }
    }, []);

    const logoutUser = async (e) => {
        e.preventDefault();
        localStorage.removeItem('datosUsuario')
        await signOut(auth);
        navigate("/login");
    }


    return (
        <>

            <Nav className="col-md-12 d-none d-md-block bg-light sidebar"
                activeKey="/homeAdmin"
            >
                <div className="sidebar-sticky"></div>
                <Nav.Item>
                    <h3>{user.email}</h3>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/homeAdmin"><h2>Home</h2></Nav.Link>
                </Nav.Item>
                {menus.find(item => item==="forms")!==undefined && (
                <Nav.Item>
                    <Nav.Link href="/forms"><h2>Administración de formularios</h2></Nav.Link>
                </Nav.Item>
                )}
                {menus.find(item => item==="users")!==undefined && (
                <Nav.Item>
                    <Nav.Link href="/usuarios"><h2>Administración de usuarios</h2></Nav.Link>
                </Nav.Item>
                )}
                {menus.find(item => item==="descarga")!==undefined && (
                <Nav.Item>
                    <Nav.Link href="/descargas"><h2>Descargar reportes</h2></Nav.Link>
                </Nav.Item>
                )}
                <Nav.Item>
                    <Button style={{ marginTop: '10px' }} size="xs" variant="boton-cerrar" onClick={(e) => logoutUser(e)} className="w-100" type="submit">
                        Cerrar sesión</Button>
                </Nav.Item>
            </Nav>

        </>
    );
};
export default Side